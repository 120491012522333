import { Box, Typography } from '@mui/material'
import React from 'react'

const NotFoundPage = () => (
  <Box
    sx={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f5f5dc',
      color: 'black',
      textAlign: 'center',
      // border: '1px solid black',
    }}
  >
    <Typography variant="h1">Дорогий гостю, скористайся з лінку який ти отримав від Юлі та Олега.</Typography>
    <Typography variant="h1">Інакше не вдасться відкрити запрошення :(</Typography>
  </Box>
)

export default NotFoundPage
