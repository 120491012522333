import { Box, Grid, Link, Typography } from '@mui/material'
import React from 'react'

const colors = ['#D1C4E9', '#FFF9C4', '#F8BBD0', '#BBDEFB', '#FFE0B2', '#C8E6C9']

const FaqPage = () => {
  return (
    <Grid
      id="faqPage"
      sx={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5dc',
        color: 'black',
        padding: '2rem', // Adding padding to ensure content doesn't touch the edges
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start', // Align left but within a centered box
          maxWidth: '800px', // Constrain the width for better readability
          width: '100%',
          gap: '2rem', // Space between each section
        }}
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            1. Як добратись?
          </Typography>
          <Typography variant="body2" paragraph>
            Варто заздалегідь забронювати квитки на літак. Візи для громадян України та Польщі не потрібні (робляться на
            місці в аеропорті - безкоштовно)
          </Typography>
          <Typography variant="body2" paragraph>
            Тут лінки на сайти де ми зазвичай бронюємо квитки:
          </Typography>
          <Typography variant="body2" paragraph>
            <Link href="https://ua.trip.com/?locale=uk_ua&curr=PLN" target="_blank" rel="noopener noreferrer">
              trip.com
            </Link>
          </Typography>
          <Typography variant="body2" paragraph>
            <Link href=" https://skiplagged.com/" target="_blank" rel="noopener noreferrer">
              skiplagged.com
            </Link>
          </Typography>
        </Box>

        <Box>
          <Typography variant="h4" gutterBottom>
            2. Проживання та транспорт
          </Typography>
          <Typography variant="body2" paragraph>
            З 12.02.2025 по 16.02.2025 гості проживатимуть в готелі або віллі, які молодята приготували для Вас. Також
            включаються трансфери з аеропорту до готелю та назад. Приватний мінівен (групові поїздки), оренда байків
            (для сміливих і незалежих).
          </Typography>
          <Typography variant="body2" paragraph>
            На острові є таксі, але вони дорогі. Публічний транспорт практично відсутній
          </Typography>
        </Box>

        <Box>
          <Typography variant="h4" gutterBottom>
            3. Одяг
          </Typography>
          <Typography variant="body2" paragraph>
            Оскільки Таїланд - тропічна країна, рекомендуємо брати з собою легке та вільне вбрання
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontWeight: 'bold',
              // fontFamily: '"Pacifico", cursive', // Using a custom font like 'Pacifico'
              fontSize: '1.2rem',
            }}
          >
            Dress code:
          </Typography>
          <Grid
            container
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              gap: '2rem', // Space between each section
              mt: 2,
              mb: 2,
              pt: 2,
              pb: 2,
            }}
          >
            {colors.map((color) => (
              <Box
                key={`color-${color}`}
                sx={{
                  width: '50px',
                  height: '50px',
                  backgroundColor: color,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            ))}
          </Grid>
          <Typography variant="body2" paragraph>
            Ми будемо дуже вдячні, якщо ви підтримаєте кольорову гамму нашого весілля
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default FaqPage
