import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { getImage } from '../service/image'

const LocationPage = () => {
  const [imageId, setImageId] = useState('17-1')

  useEffect(() => {
    const imageIds = ['17-1', '14', '12', '18']
    let index = 0

    const interval = setInterval(() => {
      index = (index + 1) % imageIds.length
      setImageId(imageIds[index])
    }, 4000)

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [])

  const rightView = getImage(imageId)

  const leftView = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: { xs: 'row', md: 'column' },
        height: '100%',
      }}
    >
      <Grid
        item
        xs={6}
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
        }}
      >
        <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Острів Самуї. Таїланд
        </Typography>
        <Typography variant="body1" gutterBottom>
          Тут супер класно. Отвічаю
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Дата:</strong> Середа 14.02.2025
        </Typography>
      </Grid>
      <Grid item xs={6} md={12} sx={{ padding: { xs: 2 }, paddingBottom: { md: 2 }, height: '100%', width: '100%' }}>
        <iframe
          title="Asan Willa at Samui"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3935.4344925907!2d99.92941697567034!3d9.470883690609144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3054f5c7797c6619%3A0x1c5721908090b63a!2sBaan%20Asan!5e0!3m2!1suk!2ssg!4v1722076414189!5m2!1suk!2ssg"
          width="100%"
          height="100%"
          allowFullScreen={true}
          loading="lazy"
        />
      </Grid>
    </Box>
  )

  return <Layout id={'locationPage'} LeftView={leftView} RightView={rightView} />
}

export default LocationPage
