import axios from 'axios'
import type { Invitation, InvitationResult } from '../../model/Invitation'

export const getInvitation = async (slug: string): Promise<{ data: Invitation; status: number }> => {
  try {
    const url = `${process.env.REACT_APP_INVITATION_API_URL}/invitation/${slug}`
    const { status, data } = await axios.get<Invitation>(url)
    console.log('getInvitation', url, status, data)
    return { status, data }
  } catch (error) {
    console.error('Error fetching invitation:', error)
    throw error
  }
}

export const updateUserResult = async (slug: string, result: InvitationResult): Promise<{ status: number }> => {
  try {
    const url = `${process.env.REACT_APP_INVITATION_API_URL}/user/${slug}`
    const { status } = await axios.put(url, { result })
    return { status }
  } catch (error) {
    console.error('Error updating user result:', error)
    throw error
  }
}
