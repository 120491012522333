import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import React, { useEffect, useState } from 'react'
import { type Invitation, InvitationResult } from '../../model/Invitation'
import Layout from '../layout/Layout'
import { updateUserResult } from '../service/apiClient'
import { getImage } from '../service/image'

type Results = {
  [slug: string]: InvitationResult
}

const InvitationFormPage = ({ invitation }: { invitation: Invitation | null }) => {
  const [results, setResults] = useState<Results>({})
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust 'sm' to 'md' if you consider tablets as well

  const [imageId, setImageId] = useState('5-2')

  useEffect(() => {
    const imageIds = ['5-2', '8', '9', '10']
    let index = 0

    const interval = setInterval(() => {
      index = (index + 1) % imageIds.length
      setImageId(imageIds[index])
    }, 4000)

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [])

  const handleRadioChange = async (userSlug: string, value: InvitationResult) => {
    setResults((prevResults) => ({
      ...prevResults,
      [userSlug]: value,
    }))
    await updateUserResult(userSlug, value)
  }

  useEffect(() => {
    if (invitation) {
      console.log('Invitation:', invitation)
    }
  }, [invitation])

  if (invitation === null) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
        <Typography sx={{ mt: 2 }}>Invitation results please wait...</Typography>
      </Backdrop>
    )
  }

  const getFormControlLabel = (result: InvitationResult, label: string) => (
    <FormControlLabel
      value={result}
      control={
        <Radio
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: {
                xs: 14,
                sm: 16,
                md: 20,
              },
            },
          }}
        />
      }
      label={label}
      sx={{
        pl: 1,
        '& .MuiFormControlLabel-label': {
          fontSize: {
            xs: '0.8rem',
            sm: '1rem',
            md: '1.2rem',
          },
        },
      }}
    />
  )

  const leftView = getImage(imageId)

  const rightView = (
    <Box
      sx={{
        backgroundColor: '#f5f5dc',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '1rem', // Space between each section
      }}
    >
      <Typography
        variant="h1"
        align="center"
        sx={{
          paddingLeft: { xs: '1rem', md: '1.5rem' },
          paddingRight: { xs: '1rem', md: '1.5rem' },
        }}
      >
        Ну то як. {invitation.users.length > 1 ? 'Приєднаєтесь' : 'Приєднаєшся'}?
      </Typography>
      <Typography
        variant="h2"
        align="center"
        sx={{
          paddingLeft: { xs: '1rem', md: '1.5rem' },
          paddingRight: { xs: '1rem', md: '1.5rem' },
        }}
      >
        Будь ласка, підвердіть Вашу присутність до 01.10.2024
      </Typography>
      <Box component="form">
        {invitation?.users
          ?.sort((a, b) => a.id - b.id)
          .map((user) => (
            <FormControl
              key={user.slug}
              component="fieldset"
              sx={{
                m: { xs: '0.4rem', sm: '0.5rem', md: '1.5rem' },
                p: { xs: '0.1rem', sm: '0.5rem' },
                border: '1px solid rgba(0, 0, 0, 0.5)',
              }}
            >
              <FormLabel
                component="legend"
                sx={{ textAlign: 'center', fontSize: { xs: '0.8rem', sm: '1rem', md: '2rem' } }}
              >
                {user.name}
              </FormLabel>
              <RadioGroup
                row={isMobile}
                aria-label="join-wedding"
                name="join-wedding"
                value={results[user.slug] || user.result}
                onChange={(e) => handleRadioChange(user.slug, e.target.value as InvitationResult)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {getFormControlLabel(InvitationResult.Accepted, 'Так, звісно')}
                {getFormControlLabel(InvitationResult.Rejected, 'Ні')}
                {getFormControlLabel(InvitationResult.Pending, 'Без поняття')}
              </RadioGroup>
            </FormControl>
          ))}
      </Box>
    </Box>
  )

  return <Layout id={'invitationQPage'} LeftView={leftView} RightView={rightView} />
}

export default InvitationFormPage
