import { Box, Button, CardMedia, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { getImage, getImageUrl } from '../service/image'

const IntroPage = () => {
  const [sideImageId, setSideImageId] = useState('1-1')
  const [smallImageId, setSmallImageId] = useState('6')

  useEffect(() => {
    const sideImageIds = ['1-1', '2', '3']
    const smallImageIds = ['6', '11', '20']
    let sideIndex = 0
    let smallIndex = 0

    const interval = setInterval(() => {
      sideIndex = (sideIndex + 1) % sideImageIds.length
      setSideImageId(sideImageIds[sideIndex])

      smallIndex = (smallIndex + 1) % smallImageIds.length
      setSmallImageId(smallImageIds[smallIndex])
    }, 4000)

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [])

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const leftView = [
    <Box
      id={'introPageHeader'}
      key={'introPageHeader'}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'absolute',
        top: { xs: '5px', md: '10px' },
        backgroundColor: '#f5f5dc',
        padding: { xs: '1px 0', md: '10px 0' },
      }}
    >
      <Button color="inherit" sx={{ mx: { xs: 0.5, md: 2 } }} onClick={() => scrollToElement('invitationQPage')}>
        REGISTRY
      </Button>
      <Button color="inherit" sx={{ mx: { xs: 0.5, md: 2 } }} onClick={() => scrollToElement('locationPage')}>
        LOCATION
      </Button>
      <Button color="inherit" sx={{ mx: { xs: 0.5, md: 2 } }} onClick={() => scrollToElement('faqPage')}>
        FAQS
      </Button>
    </Box>,
    <Box
      id={'introPageInfo'}
      key={'introPageInfo'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ position: 'relative', textAlign: 'center', color: 'white' }}>
        <CardMedia
          component="img"
          src={getImageUrl(smallImageId)}
          alt="Background"
          sx={{
            height: { xs: '12rem', sm: '14rem', md: '20rem' },
            width: { xs: '12rem', sm: '14rem', md: '20rem' },
            opacity: 0.7,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translate(-50%, -100%)',
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            variant="h1"
            sx={{ whiteSpace: 'nowrap', color: 'black', fontSize: { xs: '3rem', sm: '4rem', md: '5rem' } }}
          >
            Oleh & Yulia
          </Typography>
        </Box>
      </Box>

      <Box sx={{ pt: 3 }}>
        <Typography variant="body2">14 FEBRUARY 2025</Typography>
        <Typography variant="body2">IN THE HEART OF THAILAND</Typography>
      </Box>
    </Box>,
  ]

  // const rightView = getImage('1-1')
  const rightView = getImage(sideImageId)

  return <Layout id={'introPage'} LeftView={leftView} RightView={rightView} />
}

export default IntroPage
