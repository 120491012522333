import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Fab } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'

const BackToTopButton = () => {
  const [visible, setVisible] = useState(false)
  const theme = useTheme()

  const toggleVisibility = useCallback(() => {
    if (window.pageYOffset > 300) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [toggleVisibility])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div>
      {visible && (
        <Fab
          onClick={scrollToTop}
          color="primary"
          aria-label="scroll back to top"
          sx={{
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            width: 56, // Default width
            height: 56, // Default height
            [theme.breakpoints.down('sm')]: {
              width: 40, // Smaller width on small screens
              height: 40, // Smaller height on small screens
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </div>
  )
}

export default BackToTopButton
