import { Box, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import type { User } from '../../model/Invitation'

const VideoPage = ({ users }: { users: User[] | undefined }) => {
  return (
    <Grid
      id="videoPage"
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5dc',
        color: 'black',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography variant="h3" gutterBottom>
          {users && users.length > 1 ? 'Дорогі гості' : 'Дорогий гостю'}
        </Typography>
        <Typography variant="h3" gutterBottom>
          Запрошуємо {users && users.length > 1 ? 'Вас' : 'Тебе'} на наше весілля, котре відбудеться 14.02.2025
        </Typography>
      </Box>
      <CardMedia
        component="video"
        src="https://prime-assets.fra1.cdn.digitaloceanspaces.com/invitation-v4.MP4"
        controls
        autoPlay
        sx={{
          height: { xs: 'auto' },
          width: { xs: '100%', md: '70vw' },
          p: 3,
        }}
      />
    </Grid>
  )
}

export default VideoPage
