import { Box } from '@mui/material'
import React from 'react'

const imageUrl = 'https://prime-assets.fra1.cdn.digitaloceanspaces.com/invitation/'
export const getImageUrl = (id: string): string => {
  return `${imageUrl}${id}.png`
}

export const getImage = (id: string) => {
  return (
    <Box
      component="img"
      src={getImageUrl(id)}
      alt="Example"
      loading="lazy"
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      }}
    />
  )
}
