export interface Invitation {
  id: number
  slug: string
  title: string
  users: User[]
  createdAt: Date
  updatedAt: Date
}

export interface User {
  id: number
  slug: string
  name: string
  email: string
  result: InvitationResult
}

export enum InvitationResult {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}
