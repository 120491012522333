import { Backdrop, CircularProgress, Grid } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import type { Invitation } from '../model/Invitation'
import FaqPage from './pages/FaqPage'
import IntroPage from './pages/IntroPage'
import InvitationFormPage from './pages/InvitationFormPage'
import LocationPage from './pages/LocationPage'
import VideoPage from './pages/VideoPage'
import { getInvitation } from './service/apiClient'

const MainOnePage = () => {
  const { slug } = useParams()
  const [isValidId, setIsValidId] = useState<boolean | null>(null)
  const [invitation, setInvitation] = useState<Invitation | null>(null)

  const validateInvitation = useCallback(async (slug: string) => {
    try {
      const { status, data } = await getInvitation(slug)
      if (status === 200) {
        setInvitation(data)
        setIsValidId(true)
      }
    } catch {
      setIsValidId(false)
    }
  }, [])

  useEffect(() => {
    if (slug) {
      ;(async () => {
        await validateInvitation(slug)
      })()
    }
  }, [slug, validateInvitation])

  if (isValidId === null) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  if (!isValidId) {
    return <Navigate to="/" />
  }

  return (
    <Grid container sx={{ flexGrow: 1, flexDirection: 'column' }}>
      <IntroPage />
      {/*<InvitationTxt users={invitation?.users} />*/}
      <VideoPage users={invitation?.users} />
      <InvitationFormPage invitation={invitation} />
      <LocationPage />
      <FaqPage />
    </Grid>
  )
}

export default MainOnePage
