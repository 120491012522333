import { Box, Grid } from '@mui/material'
import type { FC, ReactNode } from 'react'
import React from 'react'

interface ParentComponentProps {
  id: string
  LeftView: ReactNode
  RightView: ReactNode
}

const Layout: FC<ParentComponentProps> = ({ id, LeftView, RightView }) => {
  return (
    <Box
      id={id}
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5dc',
        color: 'black',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '50vh' },
            minHeight: { xs: '50%', md: '100%' },
          }}
        >
          {LeftView}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '50vh' },
            minHeight: { xs: '50%', md: '100%' },
          }}
        >
          {RightView}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Layout
