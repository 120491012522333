import React from 'react'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import MainOnePage from './components/MainOnePage'
import NotFoundPage from './components/NoFoundPage'
import BackToTopButton from './components/layout/BackToTopButton'

const App = () => {
  return (
    <Router>
      <BackToTopButton />
      <Routes>
        <Route path="/:slug" element={<MainOnePage />} />
        <Route path="/" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  )
}

export default App
