// theme.js
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:960px)': {
        fontSize: '3rem',
      },
    },
    h2: {
      fontSize: '1.0rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:960px)': {
        fontSize: '2.0rem',
      },
    },
    h4: {
      fontSize: '1.8rem', // Base Size (xs, below 600px)
      '@media (min-width:600px)': {
        fontSize: '1.8rem', // sm (600px and above)
      },
      '@media (min-width:960px)': {
        fontSize: '2.8125rem', // md (960px and above)
      },
      '@media (min-width:1280px)': {
        fontSize: '3rem', // lg (1280px and above)
      },
      // Optionally, add xl if needed
      '@media (min-width:1920px)': {
        fontSize: '3.4375rem', // xl (1920px and above)
      },
    },

    h3: {
      fontFamily: '"Great Vibes", sans-serif',
      fontSize: '1.8rem', // Base Size (xs, below 600px)
      '@media (min-width:600px)': {
        fontSize: '1.8rem', // sm (600px and above)
      },
      '@media (min-width:960px)': {
        fontSize: '2.8125rem', // md (960px and above)
      },
      '@media (min-width:1280px)': {
        fontSize: '3rem', // lg (1280px and above)
      },
      // Optionally, add xl if needed
      '@media (min-width:1920px)': {
        fontSize: '3.4375rem', // xl (1920px and above)
      },
    },
    body1: {
      fontSize: '0.8rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.5rem',
      },
    },
    body2: {
      fontSize: '0.7rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.2rem',
      },
    },

    button: {
      fontFamily: '"Arial", sans-serif', // Corrected font family for buttons

      // fontWeight: 500, // Font weight for buttons
      textTransform: 'uppercase', // Uppercase text transformation
      fontSize: '0.8rem',
      '@media (min-width:600px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.2rem',
      },
    },
    // Add other typography styles as needed
  },
})

export default theme
